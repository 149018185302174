<div class="panel panel-primary">
	<div class='panel-heading'>
		<div class='row'>
		<div class='col-md-11'>
			Label Data Processing
		</div>
		<div class='col-md-1 col-xs-12 div-list-sml'>
			<button class='btn btn-primary btn-sm btn-list-sml' style='width: auto;' (click)='help()'>how-to</button>
		</div>
	</div>
	</div>
	<div class="panel-body">
		<div class='row'>
			<div class='col-md-12'>
				<div class='has-error' class='text-danger' *ngIf='errorMessage'>
					<button class='btn btn-primary btn-sm' (click)='resetError()'>Reset Error</button>
					{{ errorMessage }}
				</div>
			</div>
			
		</div>
		<div>
			<div class="row">
				<h2>Step 1: Upload file</h2>
			</div>
			<div class="row">
				<div class="col-md-4 divbtn">
					<button class='btn btn-primary' style="width: 215px;" [routerLink]="['/productdata-upload']" [disabled]='progressStatus'>Upload
						Product Data File</button>
				</div>
				<div class="col-md-4">
					Last Execution : {{ProductDataDate | date:'dd/MM/yyyy hh:mm:ss'}}
				</div>
			</div>
			<div class="row">
				<br />
				<br />
				<h2>Step 2: Process File  (note, very heavy load to server)</h2>
			</div>

			<div class="row">
				<div class="col-md-4 divbtn">
					<button class='btn btn-primary' (click)="executeAllPropertys()" style="width: 215px;" [disabled]='progressStatus'>Execute All
						Propertys</button>
				</div>
				<div class="col-md-4">
					Last Execution : {{exePropertyDate | date:'dd/MM/yyyy hh:mm:ss'}}
				</div>
				<div class="col-md-4" style="color: blue;">
					{{exePropertyStatus}}
				</div>
			</div>
			<div class="row">
				<br />
				<br />
				<h2>Step 3: Build 1st batch of label PDFs (note, very heavy load to server)</h2>
			</div>

			<div class="row">
				<div class="col-md-4 divbtn">
					<button class="btn btn-primary" (click)="generateAllProdGroupRpt()" style="width: 215px;"
						[disabled]='progressStatus'>Generate All Product Group</button>
				</div>
				<div class="col-md-4">
					Last Execution : {{prodGroupDate | date:'dd/MM/yyyy hh:mm:ss'}}
				</div>
				<div class="col-md-4" style="color: blue;">
					{{prodGroupFileStatus}}
				</div>
			</div>
			<div class="row">
				<br />
				<br />
				<h2>Step 4: Build 2nd batch of label PDFs (note, very heavy load to server)</h2>
			</div>
			<div class="row">
				<div class="col-md-4 divbtn">
					<button class="btn btn-primary" (click)="generateAllProdCatRpt()" style="width: 215px;"
						[disabled]='progressStatus'>Generate All Product Category</button>
				</div>
				<div class="col-md-4">
					Last Execution : {{prodCatDate | date:'dd/MM/yyyy hh:mm:ss'}}
				</div>
				<div class="col-md-4 divbtn" style="color: blue;">
					{{prodCatFileStatus}}
				</div>
			</div>
			<div class="row">
				<br /> <br />
			<br /> <br />
			<br /> <br />
				email craig&#64;eiar.ca if anything errors out on this page.  Take a screen shot of the entire window.
			</div>

			
			<br /> <br />
			<a [routerLink]="['/partcats']">View Part Category Data (needs to be fixed)</a>
			<br /> <br />
			<br /> <br />
		</div>
	</div>
</div>
<div>
	<mat-spinner color="primary" class="spinner" *ngIf="progressStatus"></mat-spinner>
</div>